<div *ngIf="returnLogged()">
    <div class="navBar">
        <app-navbar></app-navbar>
    </div>
    <div class="contenido">
        <router-outlet></router-outlet>
    </div>
</div>
<div *ngIf="!returnLogged()">
    <app-loginv2></app-loginv2>
</div>