<div class="pagina_completa" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <app-loader [loader]="isLoading"></app-loader>
    <div class="form-area">
        <form #formInput="ngForm" class="common-form">
            <div class="linea">
                <span class="input-container">
                        Modo de Paso
                        <mat-slide-toggle [(ngModel)]="isPassageModeToggleOn"
                            (change)="onPassageModeToggleChange($event)" name="any"></mat-slide-toggle>
                    </span>
            </div>
            <div *ngIf="isPassageModeToggleOn" class="linea">
                <span class="input-container">
                        <div *ngFor="let day of weekDays">
                            <label>
                                <input type="checkbox" name="selectedDays" value="day.value" [checked]="day.checked"
                                    (change)="onCheckboxChange($event, day)" />
                                {{ day.name }}
                            </label>
                        </div>
                    </span>
            </div>
            <div *ngIf="isPassageModeToggleOn" class="linea">

                <span class="input-container">
                        Todas las Horas
                        <mat-slide-toggle [(ngModel)]="isAllHoursToggleOn" name="isAllHoursToggleOn"
                            (change)="onAllHoursToggleChange($event)"></mat-slide-toggle>
                    </span>
            </div>
            <div *ngIf="!isAllHoursToggleOn && isPassageModeToggleOn" class="linea">

                <span class="input-container">
                        Hora de Inicio
                        <mat-form-field appearance="fill">
                            <mat-label>Inicio</mat-label>
                            <input matInput [ngxTimepicker]="startTimePicker" name="startHour" placeholder="HH:mm"
                                required="required" [format]="24" [(ngModel)]="startHour" />
                        </mat-form-field>
                        <ngx-material-timepicker #startTimePicker></ngx-material-timepicker>
                        Hora Final
                        <mat-form-field appearance="fill">
                            <mat-label>Final</mat-label>
                            <input matInput [ngxTimepicker]="endTimePicker" name="endHour" placeholder="HH:mm"
                                required="required" [format]="24" [(ngModel)]="endHour" />
                        </mat-form-field>
                        <ngx-material-timepicker #endTimePicker></ngx-material-timepicker>
                    </span>
            </div>
            <p class="error">{{error}}</p>
            <div class="linea">
                <span style="display: flex;justify-content:space-around;">
                        <button class="form-button" (click)="popupService.passageMode=false">Volver</button>
                        <button class="form-button" name="button" type="submit" (click)="cambiarPassageMode()">Guardar</button>
                    </span>
            </div>
        </form>
    </div>
</div>