<div class="pagina_completa" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="form-area">
        <h2>Transferencia de Gateway</h2>
        <p style="font-size: 14px; margin-bottom: 50px;">El gateway y la cerradura deben pertenecer al mismo administrador principal para que se conecten.</p>
        <form #formInput="ngForm" class="common-form">
            <div class="linea_alternative">
                Cuenta de Destinatario
                <span class="input-container">
                        <input type="text" class="form-input" name="recieverUsername" [(ngModel)]="recieverUsername"
                            required="" style="text-indent: 5px;"/>
                    </span>
            </div>
            <div class="linea_alternative">
                Dispositivo
                <span class="input-container">
                    <button (click)="openHubSelector()">+</button>
                </span>
            </div>
            <p class="error">{{error}}</p>
            <div class="linea">
                <span style="display: flex;justify-content:space-around;">
                            <button class="form-button" (click)="popupService.transferHub=false">Volver</button>
                            <button class="form-button" name="button" type="submit" (click)="transferir()">Transferir</button>
                        </span>
            </div>
        </form>
    </div>
</div>
<app-pop-up *ngIf="popupService.selectHubsForTransfer" style="z-index: 10;"></app-pop-up>