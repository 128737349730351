<div class="pagina_completa" *ngIf="this.popupService.createEkey" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <app-loader [loader]="isLoading"></app-loader>
    <div class="form-area">
        <div class="multipleButton">
            <button (click)="toMultipleEkeys()" title="Crear más de una eKey al mismo tiempo para la(s) cerradura(s) designada(s)">Generar múltiples
                eKeys</button>
        </div>
        <form #formInput="ngForm" class="common-form">
            <div class="linea">
                <span class="input-container" [title]="''">
                    Cuenta de Destino
                    <input type="text" class="form-input" name="recieverName" placeholder="email/celular"
                        [(ngModel)]="recieverName" />
                </span>
            </div>
            <div class="linea">
                <span class="input-container">
                    Nombre de la eKey
                    <input type="text" class="form-input" name="name" placeholder="..." ngModel />
                </span>
            </div>
            <div class="linea">
                <span class="input-container">
                    Tipo de eKey
                    <select #types name="ekeyType" (change)="onSelected(types.value)" ngModel
                        style="width: 202px; height: 35px; border-radius:20px;border:1px solid black; text-indent:10px;">
                        <option default value="">No seleccionado</option>
                        <option value="1">Permanente</option>
                        <option value="2">Temporal</option>
                    </select>
                </span>
            </div>
            <div class="linea">
                <span class="input-container">
                    Cerradura
                    <button class="addLocks" (click)="openLockSelector()">+</button>
                </span>
            </div>
            <div class="linea" *ngIf="selectedType==='4'">
                <span class="input-container">
                    <div *ngFor="let day of weekDays">
                        <label>
                            <input type="checkbox" name="selectedDays" value="day.value" [checked]="day.checked"
                                (change)="onCheckboxChange($event, day)" />
                            {{ day.name }}
                        </label>
                    </div>
                </span>
            </div>
            <div class="linea" *ngIf="selectedType==='2' || selectedType==='4'">
                <span class="input-container">
                    Fecha de inicio
                    <mat-form-field appearance="fill">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="startDatePicker" name="startDate" placeholder="DD/MM/YYYY"
                            required="required" ngModel (dateChange)="startTimePicker.open()">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                    Hora de inicio
                    <mat-form-field appearance="fill">
                        <mat-label>Hora</mat-label>
                        <input matInput [ngxTimepicker]="startTimePicker" name="startHour" placeholder="HH:mm"
                            required="required" [format]="24" ngModel>
                    </mat-form-field>
                    <ngx-material-timepicker #startTimePicker></ngx-material-timepicker>
                </span>
            </div>
            <div class="linea" *ngIf="selectedType==='2' || selectedType==='4'">
                <span class="input-container">
                    Fecha de finalización
                    <mat-form-field appearance="fill">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="endDatePicker" name="endDate" placeholder="DD/MM/YYYY"
                            required="required" ngModel (dateChange)="endTimePicker.open()">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                    Hora de finalización
                    <mat-form-field appearance="fill">
                        <mat-label>Hora</mat-label>
                        <input matInput [ngxTimepicker]="endTimePicker" name="endHour" placeholder="HH:mm"
                            required="required" [format]="24" ngModel>
                    </mat-form-field>
                    <ngx-material-timepicker #endTimePicker></ngx-material-timepicker>
                </span>
            </div>
            <div class="linea">
                <span class="input-container">
                    Apertura Remota
                    <mat-slide-toggle name="remoteUnlock" ngModel></mat-slide-toggle>
                </span>
            </div>
            <div class="linea">
                <span class="input-container" [title]="'Un Administrador Autorizado es capaz de crear y borrar más accesos(eKeys, códigos, tarjetas, etc)'">
                    Administrador Autorizado
                    <mat-slide-toggle name="keyRight" ngModel></mat-slide-toggle>
                </span>
            </div>
            <div class="linea" *ngIf="isEmailNotificationRequired()">
                <span class="input-container" [title]="'Rellena este campo si quieres que el destinatario reciba una notificación por correo'">
                    Correo de notificación
                    <input type="text" class="form-input" name="email" placeholder="..." ngModel />
                </span>
            </div>
            <p class="error">{{error}}</p>
            <div class="linea">
                <span style="display: flex;justify-content:space-around;">
                    <button class="form-button" (click)="popupService.createEkey=false">Volver</button>
                    <button class="form-button" (click)="botonGenerarEkey(formInput.value)">Generar</button>
                </span>
            </div>

        </form>
    </div>
</div>
<app-pop-up *ngIf="popupService.emailSuccess || popupService.selectLocksForEkey"></app-pop-up>